
#fans-main {
    
    max-width: 80ch;
}


#fans-body {
    display :flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center;
    min-height: 100vh;
    background-color: #282c34;
    justify-content: center;
    font-size:  max(0.5vw - 1em, 2rem); 
    /* calc(10px + 2vmin); */
    color: white;
    
    
}


#fans-gallery{
    display:flex;
    flex-direction: "row";
}

.fans-gallery-button {
    min-width: 100px;
    min-height: 100px
}


#artist-image > img {
    align-self: center;
    width: 100%;
    max-width: 400px;
    height: auto;
  }

.signup_input{
    font-size: 26px;
}

#artist-info{
    border: solid 2px black;
    background-color: rgba(128, 128, 128, 0.2);
    /*padding: 3vw;*/
    /*margin: 3vh;*/
    border-radius: 15px; 
}

#message{
    font-size: 24px;
    width: 100%;
    max-width: 400px;
}

#send{
    font-size: 24px;
    width: 100%;
    max-width: 400px;

}

.message_box {
    display: flex;
    flex-direction: column;
}

#sendMsg{
    border: solid 2px black;
    background-color: rgba(128, 128, 128, 0.2);
    padding: 3vw;
    margin: 3vh;
    border-radius: 15px; 
    
}



.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-gap: 20px;
}



.App-logo {
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: auto;
}

.Avatar{
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: auto;
}

.qr_code {
  grid-area: qr;
  padding: 3vh;
  background-color: rgba(128, 128, 128, 0.2);
  border: solid 2px black;
  padding: 3vw;
  margin: 3vh;
  border-radius: 15px;
  
}

.header {
  grid-area: header;
}

.footer {
  grid-area: footer;
}


.chat_box {
  border: solid 2px black;
  background-color: rgba(128, 128, 128, 0.2);
  padding: 3vw;
  height: 90vh;
  width: 60vw;
  margin: 3vh;
  grid-area: chat;
  text-align: end;
  border-radius: 15px;
  overflow-y:scroll;
}

.chat_box li {
  list-style-type: none;
  padding:2vh;
}

.chat_box li:nth-child(odd) {
  background-color: rgba(128, 128, 128, 0.4);
}

.report_link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report_link a {
  display: inline-block;
  padding: 10px 20px;
  background-color: rgba(128, 128, 128, 0.2);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.report_link a:hover {
  background-color: rgba(128, 128, 128);
}

.page_views{
  grid-area: views;
  border: solid 2px black;
  background-color: rgba(128, 128, 128, 0.2);
  padding: 3vw;
  margin: 3vh;
  border-radius: 15px; 
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 896px) {
  .App {
    grid-template-columns: auto;
    grid-template-areas:
      "header"
      "qr"
      "chat"
      "views"
      "report"
      "footer";
  }

}

@media only screen and (min-width: 897px) {
  .App {
    grid-template-areas:
      "header header"
      "qr chat"
      "views chat"
      "views report"
      "footer footer";
    
    
  }

  .App-logo {
    align-items: center;
  }

  .chat_box {
    border: solid 2px black;
    width:40vw;
    
  }

  .Avatar {
    align-items: center;
    width: 100%;
    max-width: 400px;
    height: auto;
  }


}

#email{
    font-size: 24px;
    width: 100%;
    max-width: 400px;
}

#submit{
    font-size: 24px;
    width: 100%;
    max-width: 400px;
}

#signup{
    border: solid 2px black;
    background-color: rgba(128, 128, 128, 0.2);
    padding: 3vw;
    margin: 3vh;
    border-radius: 15px; 
}